import { Divider, Link, makeStyles, Typography, withStyles } from '@material-ui/core';
import classNames from "classnames";
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface Props {
  source: string;
  removeParagraphSpacing?: boolean;
}

const ALLOWED_ELEMENTS = [
  '**',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'a',
  'p',
  'br',
  'em',
  'sup',
  'sub',
  'strong',
  'hr',
  'del',
  'table',
  'thead',
  'tbody',
  'tr',
  'th',
  'td',
  'ol',
  'ul',
  'li'
];

const useStyles = makeStyles({
  heading: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  paragraph: {
    marginBottom: '1em'
  },
  paragraphNoSpacing: {
    marginBottom: 0
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)'
  }
})

const Markdown = (props: Props) => {
  const { source, removeParagraphSpacing } = props;
  const classes = useStyles();

  return (
    <ReactMarkdown
      children={source}
      linkTarget="_blank"
      allowedElements={ALLOWED_ELEMENTS}
      remarkPlugins={[remarkGfm]}
      components={{
        h1: () => <Typography variant="h1" />,
        h2: () => <Typography variant="h2" />,
        h3: () => <Typography variant="h3" />,
        h4: () => <Typography variant="h4" />,
        h5: () => <Typography variant="h5" />,
        h6: () => <Typography variant="h6" />,
        p: (props) => <Typography
          {...props}
          color="inherit"
          className={classNames(classes.paragraph, removeParagraphSpacing && classes.paragraphNoSpacing)}
        />,
        a: (props) => <Link {...props} color="inherit" />,
        hr: (props) => <Divider {...props} orientation="horizontal" className={classes.divider} />
      }}
    />
  );
};

export default withStyles({
  heading: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  paragraph: {
    marginBottom: '1em'
  },
  paragraphNoSpacing: {
    marginBottom: 0
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)'
  }
})(Markdown);
